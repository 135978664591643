@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.travellers-room-form {
	&__form {
		height: 100%;

		@include gridle_state(md) {
			max-height: calc(100vh - 200px);
			overflow: auto;
			padding-top: unset;
		}
	}

	&__popover-body {
		display: flex;
		flex-direction: column;
		padding-bottom: 180px;

		@include gridle_state(md) {
			padding: 0;
		}
	}

	&__fieldset {
		border-bottom: 1px solid $grey-light;
	}

	&__add-bedroom {
		background: white;
		padding: 17px 16px;
		@extend %font-bold;

		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		color: $grey-medium;
		font-size: 2rem;

		@include gridle_state(md) {
			font-size: 1.5rem;
		}
	}

	&__add-bedroom-button {
		@extend %link;

		display: flex;
		align-items: center;
		font-size: 1.2rem;
		text-decoration: underline;
		cursor: pointer;

		svg {
			margin-right: 9.5px;
		}

		&--disabled {
			pointer-events: none;
			color: var(--color-gray-500, $grey-medium);
		}
	}

	&__validate-button {
		margin-top: auto;
		padding: 17px 16px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: white;

		@include gridle_state(md) {
			position: relative;
			margin: unset;
			border-bottom-left-radius: $border-radius-big;
			border-bottom-right-radius: $border-radius-big;
		}

		button {
			box-shadow: none;
		}
	}
}
