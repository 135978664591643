@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
$border-radius-big: 5px !default;

.smartDP-input-side-panel {
	background: white;
	height: 100vh;

	.sdp-search-form__field {
		border: none;
	}

	&__content {
		position: fixed;
		height: 100%;
		overflow: hidden;

		.advanced-select {
			height: calc(100% - 84px); // 84px = height of header + padding
			.advanced-select__select-sdp {
				&__control {
					height: 56px;
					margin: 0 16px;
					background: white;
					border: 1px solid var(--color-gray-500, $input-border-color) !important;
					border-radius: $input-border-radius !important;
					box-shadow: $input-shadow-box;

					&--is-focused {
						border: $input-border !important;
						box-shadow: none !important;
					}

					&--menu-is-open {
						border: $input-border !important;
						box-shadow: none !important;
					}
				}

				&__input {
					margin-top: 0;
				}

				&__value-container {
					padding-left: 13px; // 13 et pas 15 pour tenir compte des 2px de react-select__single-value
					padding-right: 13px; // 13 et pas 15 pour tenir compte des 2px de react-select__single-value
					margin-top: 8px;
				}

				&__clear-indicator {
					padding-right: 17px;

					svg {
						color: var(--color-gray-500, $grey-medium);
					}
				}

				&__indicator-separator {
					display: none;
				}

				&__dropdown-indicator {
					display: none;
				}

				&__menu-notice {
					text-align: left;
				}
			}

			position: relative;
			box-sizing: border-box;

			&-menu {
				border: none;
				border-radius: 0;
				height: 100%;
				width: 100%;
				box-shadow: none;
			}

			&__container {
				background: white;
			}

			&__header {
				padding: 17px 16px;
				display: flex;
				align-items: center;
				color: $header-text-color;
				height: 60px;
				box-sizing: border-box;
				justify-content: space-between;
				box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
				margin-bottom: 24px;
				background-color: $header-background-color;

				&-icon {
					height: 24px;
				}
			}

			.advanced-select__label {
				left: 32px;
				top: 22px;
			}

			&--touched {
				.advanced-select__label {
					@extend %font-bold;

					top: 8px;
					padding-left: 0;
				}
			}

			&__select {
				height: 100%;

				&-sdp__menu {
					position: relative;
					top: unset;
					height: calc(100% - 54px); // 54px = height of input
					left: 0;

					&-list {
						height: 100%;
					}
				}
			}
		}

		.advanced-select__select-sdp__value-container--has-value {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;

			.advanced-select__select-sdp__single-value {
				margin-right: 0;
				width: fit-content;

				.sdp-search-form__suggestion-label {
					@extend %font-normal;

					font-weight: normal;
				}
			}

			.advanced-select__select__input-container {
				margin-left: 0;
			}
		}

		.advanced-select__select-sdp__menu {
			padding: 24px 0;
			margin-top: 0;
		}

		.location-menu-list,
		.advanced-select-menu-list {
			max-height: 72vh;

			&__container {
				padding: 0;
			}
		}

		.advanced-select-option {
			height: 56px;
			padding: 0 16px;
			background: none;
			border-bottom: 1px solid #f7f7f7;

			&:active {
				background: none;
			}
		}
	}
}

.advanced-select__select-sdp__menu {
	margin-top: 0;
}

.advanced-select__select-sdp__option--is-selected {
	background-color: $grey-light !important;
	color: black !important;
}

.smartdp-input-search-select {
	$this: &;

	position: relative;
	box-sizing: border-box;

	&__label {
		position: absolute;
		top: 18px;
		left: 15px;
		display: flex;
		align-items: center;
		transition: top 0.1s ease-out, font-size 0.1s ease-out;
		color: $grey-dark;
		pointer-events: none; // fix empeche de saisir sur device ios
	}

	&__input {
		height: 54px;
		width: 100%;
		padding-top: 17px;
		padding-bottom: 0; // the content is not centered on ios
		padding-left: 15px;
		box-sizing: border-box;
		font-size: 1.4rem;
		outline: none;
		appearance: none;
		border-radius: $border-radius-big;
		-webkit-appearance: none; // delete top big border in ios
		display: flex;
		align-items: center;
		background: white;

		@include gridle_state(md) {
			box-shadow: $input-shadow-box;
		}
	}

	&--touched {
		#{$this}__label {
			@extend %input-label-touched;

			top: 10px;
		}
	}

	&--error {
		#{$this}__label {
			@extend %font-bold;

			color: var(--color-warning-500);
		}
	}
}
