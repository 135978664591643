@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
$border-radius-big: 5px !default;

.sdp-search-form {
	$this: &;

	&__header {
		padding: 17px 16px;
		display: flex;
		align-items: center;
		color: $header-text-color;
		height: 60px;
		box-sizing: border-box;
		justify-content: space-between;
		box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
		margin-bottom: 24px;
		background-color: $header-background-color;
		z-index: 2;

		&-icon {
			height: 24px;
		}
	}

	&__back {
		display: flex;
		align-items: center;

		svg {
			height: 15px;
			width: 15px;
		}
	}

	&__title {
		@extend %font-bold;

		font-size: 2.2rem;
	}

	&__reset {
		min-width: 15px;
	}

	&__form {
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		padding: 16px 16px 24px;
		border-radius: 8px;
		flex-direction: column;
		background: white;
		box-shadow: 0 2px 4px 0 #0000001a;

		@include gridle_state(md) {
			gap: 8px 16px;
			box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
			flex-direction: unset;
			padding: 16px;
		}

		.advanced-select__icon,
		.travellers-room-button__icon {
			color: $black;
			width: 24px;

			svg {
				width: 24px;
				max-height: 24px;
			}
		}

		.advanced-select--error {
			.advanced-select__select {
				box-shadow: none;
				border: none;
				border-radius: $border-radius-big;
			}
		}

		.date-calendar-input__departure--error,
		.date-calendar-input__return--error {
			&#{$this}__field {
				border: 1px solid var(--color-warning-500);
			}
		}

		.date-calendar-input__departure--error {
			&#{$this}__field {
				border-right: unset;
			}
		}

		.date-calendar-input__return--error {
			&#{$this}__field {
				border: 1px solid var(--color-warning-500);
			}
		}
	}

	&__suggestion {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		overflow-x: hidden;

		&-picto {
			margin-right: 10px;
			display: flex;
			align-items: center;

			svg {
				width: 20px;
				max-height: 18px;
			}
		}

		&-label {
			@extend %font-bold;
		}

		&-text {
			display: flex;
			flex-direction: column;
		}

		&-first-label {
			@extend %font-bold;
		}

		&-second-label {
			color: $grey-dark;
		}
	}

	&__container {
		.location-inspiration {
			position: relative;
			padding: 16px 16px 0;
			margin-top: 32px;

			&::before {
				position: absolute;
				content: "";
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 100px;
				height: 1px;
				background-color: $grey-medium;
			}

			&__merch {
				padding: 0;

				.relative-link__text,
				.location-inspiration__merch-cta,
				.background-progressive-image {
					width: 100%;
				}
			}

			&__sales {
				padding: 0 0 32px;

				.lookalike-product {
					width: 100%;
				}
			}
		}
	}

	&__field {
		height: 56px;
		border: 1px solid var(--color-gray-500, $input-border-color);
		border-radius: $border-radius-big;
		margin-bottom: 16px;
		width: 100%;

		@include gridle_state(md) {
			flex: 1.4;
			height: 48px;
			width: unset;
			margin-bottom: 0;
			cursor: pointer;
		}

		&.advanced-select--touched {
			.advanced-select__select__value-container--has-value
			.advanced-select__select__single-value
			.sdp-search-form__suggestion-label {
				margin-top: 9px;
			}

			.advanced-select__label {
				top: 7px;
			}
		}

		.advanced-select__select__input-container {
			margin: 0;
			padding: 9px 0 0;
		}

		.advanced-select__select__control,
		.travellers-room-button__input {
			border: unset !important;
			box-shadow: unset !important;
		}

		&-departure {
			margin-bottom: 0;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			border-bottom: unset;

			.advanced-select__select__control--is-focused {
				border-bottom-left-radius: 0 !important;
				border-bottom-right-radius: 0 !important;
			}

			@include gridle_state(md) {
				margin-right: -16px;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				border-right: unset;
				border-bottom-left-radius: $border-radius-big;
				border-bottom: 1px solid var(--color-gray-500, $input-border-color);

				.advanced-select__select__control--is-focused {
					border-top-right-radius: 0 !important;
					border-bottom-right-radius: 0 !important;
					border-bottom-left-radius: $border-radius-big !important;
				}
			}
		}

		&-dates-departure {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-right: unset;
			border-bottom-left-radius: $border-radius-big;
			border-bottom: 1px solid var(--color-gray-500, $input-border-color);

			@include gridle_state(md) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				border-right: unset;
				border-bottom-left-radius: $border-radius-big;
				border-bottom: 1px solid var(--color-gray-500, $input-border-color);

				.advanced-select__select__control--is-focused {
					border-top-right-radius: 0 !important;
					border-bottom-right-radius: 0 !important;
					border-bottom-left-radius: $border-radius-big !important;
				}
			}
		}

		&-destination {
			border-top-left-radius: 0;
			border-top-right-radius: 0;

			.advanced-select__select__control--is-focused {
				border-top-left-radius: 0 !important;
				border-top-right-radius: 0 !important;
			}

			@include gridle_state(md) {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				border-top-right-radius: $border-radius-big;

				.advanced-select__select__control--is-focused {
					border-top-left-radius: 0 !important;
					border-bottom-left-radius: 0 !important;
					border-top-right-radius: $border-radius-big !important;
				}

				&-hotel-only {
					flex: 2.8;
				}
			}
		}

		&-dates-return {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			border-top-right-radius: $border-radius-big;

			.advanced-select__select__control--is-focused {
				border-top-left-radius: 0 !important;
				border-bottom-left-radius: 0 !important;
				border-top-right-radius: $border-radius-big !important;
			}
		}

		.advanced-select__select__control {
			cursor: pointer;

			&--is-focused {
				background-color: var(--color-gray-300, $grey-light);
			}
		}

		&-stopovercity,
		&-stopoverdirection {
			@include gridle_state(md) {
				flex: 1;
			}
		}

		&-cabin {
			@include gridle_state(md) {
				flex: 0.7;
			}
		}

		&--fullsize {
			width: 100%;

			@include gridle_state(md) {
				width: unset;
			}
		}
	}

	&__footer {
		flex: 1;

		svg {
			width: 20px;
			height: 20px;
		}

		.button {
			min-height: 48px;
		}
	}

	.advanced-select--error,
	.smartdp-input-search-select--error {
		border: 1px solid var(--color-warning-500);
		border-radius: $border-radius-big;
	}

	.sdp-search-form__field-departure.advanced-select--error {
		border-right: unset;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.sdp-search-form__field-departure.smartdp-input-search-select--error {
		border-bottom: unset;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.sdp-search-form__field-destination.advanced-select--error {
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}

	.sdp-search-form__field-destination.smartdp-input-search-select--error {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.advanced-select__label {
		width: 90%;

		> div {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	&__error {
		.alert-message {
			margin: 16px 0;
			text-align: center;
			border-radius: $border-radius-big;

			&__message {
				justify-content: center;
			}

			@include gridle_state(md) {
				margin: 20px 0 0;
			}
		}
	}
}
